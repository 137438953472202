@import '../../../../base'

.accounting-balances-total
  @include flex
  > div
    @include flex
    margin-right: 15px
    border: 2px solid
    border-radius: 3px
    height: 60px
    padding: 0 10px
    min-width: 300px
    > div
      text-align: right
      color: #728191
      line-height: 12px
    strong
      color: #455a71
      font-size: 15px
      line-height: 15px
      display: block
      margin-bottom: 4px

  .accounting-balances-main-wallet-total
    background: #f9fcff
    border-color: #d9e5f0
    .accounting-balances-title
      color: #1f97cf

  .accounting-balances-users-total-balances
    background: #fef8f8
    border-color: #fad6d5
    .accounting-balances-title
      color: #eb443b
  .accounting-balances-hold-as-bets
      background: #f5f6f9
      border-color: #c1cad5
      .accounting-balances-title
        color: #3f5a7b

.accounting-balances-title
  @include bold
  text-transform: uppercase
  white-space: pre-wrap
  line-height: 16px
  margin: 0 auto 0 10px

.accounting-balances-container
  display: flex
  align-items: flex-start
  margin-top: 16px
  height: calc(100% - 90px)
  overflow: auto

.accounting-balances-table
  width: 615px

.accounting-balances-hold-as-bets-table
  width: 300px
  margin-left: 15px
  background: #f8f9fa
  border-radius: 3px
  padding: 0 10px

  .accounting-balances-table-header > div
    justify-content: flex-end

.accounting-balances-table, .accounting-balances-hold-as-bets-table
  > div
    @include horizontalSeparator($color: #000)
    line-height: 12px
    @include flex
    justify-content: space-between
    > div
      flex: 1
      display: flex
      &:not(:first-of-type)
        justify-content: flex-end
      &:nth-child(3)
        min-width: 260px
.accounting-balances-table-header
  height: 28px!important
  @include bold
  color: $secondary-color
  text-transform: uppercase

.accounting-balances-table-row
  height: 62px!important
  color: rgba($primary-color, .7)
  strong
    color: $primary-color
    font-size: 14px
    margin-bottom: 4px
  svg
    width: 20px
    min-width: 20px

.accounting-balances-table-col
  @include flex
  align-items: flex-end
  justify-content: flex-end
  > div
    @include flex
    flex-direction: column
    align-items: flex-end
    > span:first-of-type
      margin-bottom: 2px
  .increased, .decreased
    font-size: 11px
    @include bold
    margin-left: 10px
    min-width: 46px

  .decreased
    color: #eb443b
  .increased
    color: #67bb77

.accounting-balances-table-crypto
  align-items: center
  > span
    display: flex
    flex-direction: column
    margin-left: 12px
    justify-content: center
